// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toggle } from '../../../components/toogle/Toogle'
// utils 
import { responseError } from '../../../utils/responsesFunctions/error.response'
//services
import { api } from '../../../services/api/api'
import { v4 as uuid } from 'uuid'
import { FiMinus, FiPlus, FiTrash2 } from 'react-icons/fi'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { justNumber } from '../../../utils/validators/justNumber'
import { validatorAccounts } from '../../../utils/validators/validatorAccounts'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'

export function BanksEdit() {

    const navigate = useNavigate()
    const { id } = useParams()
    const { screenX, screenY } = useScreenSizeContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()

    const [bankData, setBankData] = useState({})
    const [updateStates, setUpdateStates] = useState(false)
    const [accountTemp, setAccountTemp] = useState('')
    // Busca da API - contas
    const [accountsList, setAccountsList] = useState([])
    const [accountsIdList, setAccountsIdList] = useState([])
    // Novas contas
    const [accountsNewList, setAccountsNewList] = useState([])
    const [accountsNewIdList, setAccountsNewIdList] = useState([])
    // States de validação
    const [alterStatusBank, setAlterStatusBank] = useState(false)
    const [alterAccountAPI, setAlterAccountAPI] = useState(false)
    const [removeAccountLink, setRemoveAccountLink] = useState(false)

    useEffect(() => {

        async function getBank() {
            setUpdateStates(false)
            try {

                const getBankAPI = await api.get(`/api/v1/fc/banks/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setBankData(getBankAPI?.data?.data)

                const object = {}

                getBankAPI?.data?.data?.accounts.forEach(acc => {
                    object[uuid()] = {
                        id: acc.id,
                        account: acc.account,
                        agency: acc.agency,
                        description: acc.description,
                        banks_id: getBankAPI?.data?.data?.id,
                        active: acc.active,
                        balance: acc.balance,
                        precedenceDate: acc.precedenceDate
                    }
                })

                setAccountsList(object)

                setAccountsIdList(Object.keys(object))

                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getBank()

    }, [updateStates])

    useEffect(() => {
        if (!showUniversalModal) {
            setRemoveAccountLink(false)
            setAccountTemp('')
        }
    }, [showUniversalModal])

    async function handleSubmit() {
        const fields =
            [
                { "name": 'status', "value": bankData?.active, "required": true, "type": 'string' }
            ]

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return setShowNotificationModalSuccess(false)
        }

        if (Object.keys(accountsList).length > 0 && alterAccountAPI) {
            if (validatorAccounts(accountsList).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha as contas em vermelho')

            }
        }

        if (Object.keys(accountsNewList).length > 0) {
            if (validatorAccounts(accountsNewList).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha as contas em vermelho')
            }
        }
        
        setLoading(true)
        if (Object.keys(accountsList).length > 0 && alterAccountAPI) {
            try {
                await api.put(`/api/v1/fc/banksAccounts`, {
                    accounts: Object.keys(accountsList).length > 0 && Object.keys(accountsList).map(acc => {                        
                        return {
                            id: accountsList[acc].id,
                            account: accountsList[acc].account,
                            agency: accountsList[acc].agency,
                            description: accountsList[acc].description,
                            banks_id: accountsList[acc].banks_id,
                            active: accountsList[acc].active,
                            balance: accountsList[acc].balance,
                            precedenceDate: accountsList[acc].precedenceDate
                        }
                    })
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        if (Object.keys(accountsNewList).length > 0) {
            try {
                await api.post(`/api/v1/fc/banksAccounts`, {
                    accounts: Object.keys(accountsNewList).length > 0 && Object.keys(accountsNewList).map(acc => accountsNewList[acc])
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        if (alterStatusBank) {
            try {

                await api.put(`/api/v1/fc/banks/alterStatus`, {
                    id,
                    status: bankData?.active
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        setShowModificationModal(true)
        setShowNotificationModalText('Banco editado com sucesso')
        setShowNotificationModalSuccess(true)
        navigate('/banks')

    }

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/fc/banks/${bankData?.code}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Banco removido com sucesso')
            setShowNotificationModalSuccess(true)
            navigate('/banks')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const options = [
        {
            description: "Ativo",
            value: true
        },
        {
            description: "Inativo",
            value: false
        }
    ]

    function addAccount() {

        const id = uuid()
        let add

        add = Object.assign(accountsNewList, {
            [id]: {
                account: null,
                agency: null,
                description: null,
                banks_id: bankData?.id,
                balance: null,
            }
        })

        setAccountsNewList(add)
        setAccountsNewIdList([...accountsNewIdList, id])
    }

    async function removeAccount(id) {
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 50))

        const replicaNewAccounts = structuredClone(accountsNewList)
        const replicaNewAccountsList = structuredClone(accountsNewIdList)

        const position = accountsNewIdList.indexOf(id)
        replicaNewAccountsList.splice(position, 1)

        delete replicaNewAccounts[id]

        setAccountsNewList(replicaNewAccounts)
        setAccountsNewIdList([...replicaNewAccountsList])
        setLoading(false)
    }

    async function alterStatusAccount(item, id) {
        const status = !item

        try {

            await api.put(`/api/v1/fc/banksAccounts/alterStatus`, {
                id: id,
                status: status
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setUpdateStates(true)
            setShowNotificationModalText(status === false ? `Conta desativada com sucesso` : `Conta ativada com sucesso`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    async function accountRemove() {
        try {

            await api.delete(`/api/v1/fc/banksAccounts/${accountTemp}`, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            setUpdateStates(true)
            setShowNotificationModalText('Conta removida com sucesso')

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    return (
        <>
            <ScreenModal title={removeAccountLink ? 'Exclusão de conta' : 'Exclusão de banco'} width={400} height={200} >
                {
                    removeAccountLink ?
                        <div className='w-full h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir a conta?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => { accountRemove() }}>Sim</Button>
                                <Button shadow={true} approval={false} onClick={() => { setRemoveAccountLink(false); setShowUniversalModal(false) }}>Não</Button>
                            </div>
                        </div>
                        :
                        <div className='w-full h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o banco?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                                <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container> 
                <TitlePage>
                    <Title text={`Editar Banco - ${bankData?.name || ''}`} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                    <LabelInput text={'Banco'}>
                                        <Input
                                            disabled={true}
                                            value={bankData?.name}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Status'} required={true}>
                                        <InputAutoComplete
                                            width={96}
                                            data={options}
                                            optionList={["description"]}
                                            selectedLabel={'description'}
                                            id='status'
                                            onChange={(e) => {
                                                setAlterStatusBank(true)
                                                setBankData(prev => ({ ...prev, active: e.value }))
                                            }}
                                            preSelectedValue={options.filter(op => op.value == bankData?.active)[0]?.description}
                                        />
                                    </LabelInput>
                                </form>
                            </div>
                    }
                    {
                        loading ? 
                        <></>
                        : accountsIdList.length > 0 &&
                        <>
                            <div className='items-start w-full mt-3'>
                                <Title text={"Contas vinculadas ao banco"} />
                            </div>
                            {
                                Object.keys(accountsList).map((item) => {
                                    return (
                                        <>
                                            <div className={`w-full ${!accountsList[item]?.active ? 'opacity-60' : ''}`}>

                                                <div className='hidden lg:flex flex-col lg:flex-row rounded-t-2xl items-center justify-around pt-1 lg:w-[55rem] 2xl:w-[85rem] h-6 dark:shadow-secondaryDefaultDark bg-primaryDefaultLight'>
                                                    <label className='text-white'>Código da Conta</label>
                                                    <label className='text-white'>Conta</label>
                                                    <label className='text-white'>Agência</label>
                                                    <label className='text-white'>Descrição</label>
                                                    <label className='text-white'>Saldo</label>
                                                    <label className='text-white'>Data do saldo</label>
                                                </div>
                                                <div className='lg:pl-10 mb-10 flex flex-col lg:flex-row rounded-b-2xl gap-2 items-center justify-start lg:w-[55rem] 2xl:w-[85rem] dark:shadow-secondaryDefaultDark dark:bg-primaryDefaultDark lg:bg-white lg:h-20 lg:shadow-xl'>
                                                    <LabelInput text={'Código da conta'}>
                                                        <Input
                                                            disabled={true}
                                                            value={accountsList[item].id}
                                                            id={`${item} - id`}
                                                            onChange={(e) => {
                                                                setAlterAccountAPI(true)
                                                                accountsList[item].id = justNumber(e.target.value)
                                                                setAccountsList(accountsList)
                                                                setAccountsIdList([...accountsIdList])
                                                            }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '130px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput text={'Nº da conta'}>
                                                        <Input
                                                            value={accountsList[item]?.account}
                                                            id={`${item} - account`}
                                                            onChange={(e) => {
                                                                setAlterAccountAPI(true)
                                                                accountsList[item].account = justNumber(e.target.value)
                                                                setAccountsList(accountsList)
                                                                setAccountsIdList([...accountsIdList])
                                                            }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '130px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput text={'Agência'}>
                                                        <Input
                                                            value={accountsList[item]?.agency}
                                                            id={`${item} - agency`}
                                                            onChange={(e) => {
                                                                setAlterAccountAPI(true)
                                                                accountsList[item].agency = justNumber(e.target.value)
                                                                setAccountsList(accountsList)
                                                                setAccountsIdList([...accountsIdList])
                                                            }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '130px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput text={'Descrição'}>
                                                        <Input
                                                            id={`${item} - description`}
                                                            value={accountsList[item]?.description}
                                                            onChange={(e) => {
                                                                setAlterAccountAPI(true)
                                                                accountsList[item].description = e?.target.value
                                                                setAccountsList(accountsList)
                                                                setAccountsIdList([...accountsIdList])
                                                            }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '130px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput 
                                                        text={'Saldo *'}
                                                        showIconInfo={true}
                                                        messageIconInfo={'Definir saldo inicial/existente da conta.'}
                                                    >
                                                        <CurrencyInput className='
                                                                    w-[210px] lg:w-[130px] 2xl:w-[210px] px-1 border border-gray-400 border-opacity-75 
                                                                     rounded-sm outline-none transition-all duration-200
                                                                     dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark 
                                                                     dark:border-secondaryBorderDark focus:shadow-borderShadow h-8'
                                                            placeHolder={accountsList[item]?.balance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(accountsList[item]?.balance) : 'R$ 0,00'}
                                                            id={`${item} - balance`} onChange={(e) => {
                                                                setAlterAccountAPI(true)
                                                                accountsList[item].balance = parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.'))
                                                                setAccountsList(accountsList)
                                                                setAccountsIdList([...accountsIdList])
                                                            }}></CurrencyInput>
                                                    </LabelInput >
                                                    <LabelInput 
                                                        text={'Data do saldo'}
                                                        showIconInfo={true}
                                                        messageIconInfo={'Definir data que irá contar o saldo para o fluxo de caixa.'}
                                                    >
                                                        <Input
                                                            id={`${item} - precedenceDate`}
                                                            value={accountsList[item]?.precedenceDate.split('T')[0]}
                                                            type={'date'}
                                                            onChange={(e) => {
                                                                setAlterAccountAPI(true)
                                                                accountsList[item].precedenceDate = e?.target.value
                                                                setAccountsList(accountsList)
                                                                setAccountsIdList([...accountsIdList])
                                                            }} charLimit={255} width={screenX >= 1024 && screenX < 1536 ? '130px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <div className='flex flex-row gap-3 mt-4 lg:translate-x-12 lg:-translate-y-2 items-center'>
                                                        {
                                                            (userData[0]?.permissions?.indexOf('fc_banks:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                            <Tippy content={<span>{accountsList[item]?.active ? 'Inativar' : 'Ativar'}</span>}
                                                                arrow={true}
                                                                animation='perspective'
                                                                placement='top'
                                                                delay={100}>
                                                                <div>
                                                                    <Toggle status={accountsList[item]?.active ? true : false} onClick={(e) => alterStatusAccount(e, accountsList[item]?.id)} />
                                                                </div>
                                                            </Tippy>
                                                        }
                                                        {
                                                            (userData[0]?.permissions?.indexOf('fc_banks:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                            <Tippy content={<span>Remover</span>}
                                                                arrow={true}
                                                                animation='perspective'
                                                                placement='top'
                                                                delay={100}>
                                                                <div>
                                                                    <FiTrash2 className='text-red-600 text-lg cursor-pointer' onClick={() => { setAccountTemp(accountsList[item]?.id); setRemoveAccountLink(true); setShowUniversalModal(true) }} />
                                                                </div>
                                                            </Tippy>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                    }
                    {
                        accountsNewIdList.length > 0 ?
                            <>
                                <div className='items-start w-full'>
                                    <Title text={"Novas contas adicionadas"} />
                                </div>
                                {
                                    accountsNewIdList.map((item) => {
                                        return (

                                            <div className='w-full'>

                                                <div className='hidden lg:flex flex-col lg:flex-row gap-2 rounded-t-2xl items-center justify-around pt-1 lg:w-[55rem] 2xl:w-[72rem] h-8 dark:shadow-secondaryDefaultDark bg-primaryDefaultLight'>
                                                    <label className='text-white'>Conta</label>
                                                    <label className='text-white'>Agência</label>
                                                    <label className='text-white'>Descrição</label>
                                                    <label className='text-white'>Saldo</label>
                                                    <label className='text-white'>Data do saldo</label>
                                                </div>

                                                <div className='lg:pl-10 mb-10 flex flex-col lg:flex-row rounded-b-2xl gap-2 items-center justify-start lg:w-[55rem] 2xl:w-[72rem] dark:shadow-secondaryDefaultDark dark:bg-primaryDefaultDark lg:bg-white lg:h-20 lg:shadow-xl'>
                                                    <LabelInput text={'Nº da conta'}>
                                                        <Input
                                                            value={accountsNewList[item]?.account}
                                                            id={`${item} - account`}
                                                            onChange={(e) => {
                                                                accountsNewList[item].account = justNumber(e.target.value)
                                                                setAccountsNewList(accountsNewList)
                                                                setAccountsNewIdList([...accountsNewIdList])
                                                            }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '150px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput text={'Agência'}>
                                                        <Input
                                                            value={accountsNewList[item]?.agency}
                                                            id={`${item} - agency`}
                                                            onChange={(e) => {
                                                                accountsNewList[item].agency = justNumber(e.target.value)
                                                                setAccountsNewList(accountsNewList)
                                                                setAccountsNewIdList([...accountsNewIdList])
                                                            }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '150px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput text={'Descrição'}>
                                                        <Input
                                                            id={`${item} - description`}
                                                            value={accountsNewList[item]?.description}
                                                            onChange={(e) => {
                                                                accountsNewList[item].description = e?.target.value
                                                                setAccountsNewList(accountsNewList)
                                                                setAccountsNewIdList([...accountsNewIdList])
                                                            }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '150px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput 
                                                        text={'Saldo *'}
                                                        showIconInfo={true}
                                                        messageIconInfo={'Definir saldo inicial/existente da conta.'}
                                                    >
                                                        <CurrencyInput className='
                                                                     w-[210px] lg:w-[150px] 2xl:w-[210px] px-1 border border-gray-400 border-opacity-75 
                                                                     rounded-sm outline-none transition-all duration-200
                                                                     dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark 
                                                                     dark:border-secondaryBorderDark focus:shadow-borderShadow h-8'
                                                            id={`${item} - balance`} placeHolder='R$ 0,00' onChange={(e) => {
                                                                accountsNewList[item].balance = parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.'))
                                                                setAccountsNewList(accountsNewList)
                                                                setAccountsNewIdList([...accountsNewIdList])
                                                            }}></CurrencyInput>
                                                    </LabelInput >
                                                    <LabelInput 
                                                        text={'Data do saldo'}
                                                        showIconInfo={true}
                                                        messageIconInfo={'Definir data que irá contar o saldo para o fluxo de caixa.'}
                                                    >
                                                        <Input
                                                            id={`${item} - precedenceDate`}
                                                            value={accountsNewList[item]?.precedenceDate}
                                                            type={'date'}
                                                            onChange={(e) => {
                                                                accountsNewList[item].precedenceDate = e?.target.value
                                                                setAccountsNewList(accountsNewList)
                                                                setAccountsNewIdList([...accountsNewIdList])
                                                            }} charLimit={255} width={screenX >= 1024 && screenX < 1536 ? '150px' : '210px'}
                                                        />
                                                    </LabelInput>
                                                    <div className='flex flex-row gap-3 mt-4 lg:translate-x-20 lg:-translate-y-2'>
                                                        <Tippy content={<span>Adicionar</span>}
                                                            arrow={true}
                                                            animation='perspective'
                                                            placement='top'
                                                            delay={100}>
                                                            <div>
                                                                <FiPlus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-xl 2xl:text-3xl cursor-pointer' onClick={() => addAccount()} />
                                                            </div>
                                                        </Tippy>
                                                        <Tippy content={<span>Remover</span>}
                                                            arrow={true}
                                                            animation='perspective'
                                                            placement='top'
                                                            delay={100}>
                                                            <div>
                                                                <FiMinus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-xl 2xl:text-3xl cursor-pointer' approval={true} shadow={true} onClick={() => removeAccount(item)} />
                                                            </div>
                                                        </Tippy>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </>
                        : loading ? 
                            <></>
                        :   (
                            <div className='flex flex-col justify-center items-center w-full gap-1 mt-2'>
                                {
                                    (userData[0]?.permissions?.indexOf('fc_banks:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                                    <div className='flex flex-col text-white gap-1 items-center mt-2 left-28'>
                                        <LabelInput>
                                            <Title text={'Adicionar Conta'} />
                                        </LabelInput>
                                        <FiPlus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-lg 2xl:text-xl cursor-pointer' onClick={() => addAccount()} />
                                    </div>
                                }
                            </div>
                        )
                    }
                    <div className='mt-7 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                        <Button
                            approval={false}
                            shadow={true}
                            onClick={() => { navigate('/banks'); setShowModificationModal(false) }}
                        > Cancelar
                        </Button>
                        <div className='flex gap-2'>
                            {
                                (userData[0]?.permissions?.indexOf('fc_banks:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                <Button
                                    approval={false}
                                    shadow={true}
                                    onClick={() => setShowUniversalModal(true)}
                                >Excluir
                                </Button>
                            }
                            {
                                (userData[0]?.permissions?.indexOf('fc_banks:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                <Button
                                    approval={true}
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Editar
                                </Button>
                            }
                        </div>
                    </div>
                </Body>
            </Container>
        </>
    )
}