import React, { useState } from 'react'
import { useEffect } from 'react'
import { useAuth } from '../../contexts/useAuth'
import { FaArrowDown, FaArrowUp, FaSearch } from 'react-icons/fa'
import { RiArrowUpDownLine } from 'react-icons/ri'
import { api } from '../../services/api/api'
import { formattTimeToShow } from '../../utils/formatData/formatTimeToShow'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { Button } from '../buttons/button.default'
import { LoadingPulse } from '../loaders/loadingPulse'

export function TableScrollDefault({ collumns, onClick, width, customFunctions, cursorPointer, apiUrl, filters, quantity, mobileFunctions, status }) {
    const { userData } = useAuth()
    const [sortBy, setSortBy] = useState('')
    const [loading, setLoading] = useState(true)
    const [dynamicloading, setDynamicLoading] = useState(false)
    const [sortByDirection, setSortByDirection] = useState(true)
    const [tableData, setTableData] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [lastFilter, setlastFilter] = useState(0)
    const [selectedPage, setSelectedPage] = useState(1)
    const [listenButton, setListenButton] = useState(false)

    useEffect(() => {
        async function getData() {
            try {

                const urlContructor = []

                if (selectedPage === 1 || !listenButton) setLoading(true)

                if (selectedPage > 1) setDynamicLoading(true)

                urlContructor.push(`page=${selectedPage}`)
                if (filters) {
                    Object.keys(filters).map(key => {
                        if (![null, undefined, '', NaN].includes(filters[key])) {
                            urlContructor.push(`${key}=${filters[key]}`)
                        }
                    })
                }
                if (sortBy) {
                    urlContructor.push(`orderBy=${sortBy}&orderByDirection=${sortByDirection ? 'ASC' : 'DESC'}`)
                }

                const data = await api.get(apiUrl + '?' + urlContructor.join('&'), {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                if (listenButton === true) {
                    await new Promise(resolve => setTimeout(resolve, 300))
                    const newTableData = tableData.concat(data.data.data)
                    setTableData(newTableData)
                    setTotalPages(data.data.totalPages)
                    setListenButton(false)
                } else {
                    // setLoading(true)
                    setSelectedPage(1)
                    setTotalPages(data.data.totalPages)
                    setTableData(data.data.data)
                    setLoading(false)
                }
                setlastFilter(JSON.stringify(filters))
                // setLoading(false)
                setDynamicLoading(false)
            } catch (error) {
                setLoading(false)
                setDynamicLoading(false)
            }

        }
        getData()

    }, [filters, apiUrl, selectedPage, sortByDirection, sortBy, status])

    //function to get data when the user scrolling
    async function ScrollLoading() {
        await new Promise(resolve => setTimeout(resolve, 300))
        const intersectionObserver = new IntersectionObserver(entries => {
            if (entries?.some(entry => entry?.isIntersecting)) {
                setListenButton(true); setSelectedPage(selectedPage + 1)
            }
        })
        if (document.querySelector('#testes')){
            intersectionObserver?.observe(document.querySelector('#testes'));
            return () => intersectionObserver.disconnect();
        } else {
            return null
        }
    }
    if (totalPages > 1) {

        ScrollLoading()
    } else {

    }

    return (
            loading ?
            <div className='w-full h-full overflow-hidden flex items-center justify-center'>
                <LoadingPulse />
            </div>
            :
            <div className={`  dark:border dark:border-secondaryBorderDark w-full max-h-[40rem] overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                <div className='sm:flex hidden'>                    
                    <table className='select-none border-collapse table-auto overflow-y-auto scrol w-full '>
                        <thead>
                            <tr>
                                {
                                    collumns?.map((titleColumn, indexTh) => {
                                        return <th onClick={(e) => {
                                            setSortByDirection(!sortByDirection);
                                            setSortBy(titleColumn?.name);
                                        }} key={indexTh} className='bg-primaryDefaultLight text-sm dark:bg-thirdDefaultDark text-white font-extrabold h-10 sticky top-0'>
                                            <div className='flex flex-row items-center justify-center w-full'>
                                                <p className='flex gap-2 cursor-pointer w-fit'>
                                                    {titleColumn?.description}
                                                    {
                                                        sortBy !== titleColumn?.name ?
                                                            <RiArrowUpDownLine size={'20'} />
                                                            : sortByDirection ?
                                                                <FaArrowUp size={'14'} />
                                                                :
                                                                <FaArrowDown size={'14'} />
                                                    }
                                                </p>
                                            </div>
                                        </th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>

                            {
                                tableData?.length === 0 ?
                                    <tr key={'1'} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200 '>
                                        <td colSpan={collumns.length} key={`1-1`} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>Sem registros</td>
                                    </tr>
                                    :
                                    tableData?.map((apiData, index) => {
                                        return (
                                            <tr key={index} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200 '>
                                                {
                                                    collumns?.map((collumn, indexCollum) => {
                                                        return (customFunctions?.isValid && customFunctions?.collumn == indexCollum) ?
                                                            <td
                                                                key={`${indexCollum}-${index}`}
                                                                className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}
                                                            >
                                                                {
                                                                    collumn.type == 'date'
                                                                        ? formattTimeToShow(apiData[collumn.name]).split(' ')[0]
                                                                        : collumn?.func ? collumn?.func(apiData)
                                                                            : apiData[collumn.name]}
                                                            </td>
                                                            :
                                                            <td
                                                                key={`${indexCollum}-${index}`}
                                                                onClick={() => onClick(apiData)}
                                                                className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''}   text-center text-gray-900 dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}
                                                            >
                                                                {
                                                                    collumn.type == 'date' ? formattTimeToShow(apiData[collumn.name])?.split(' ')[0]
                                                                        : collumn.type == 'currency' ? Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(apiData[collumn.name])
                                                                            : collumn?.func ? collumn?.func(apiData)
                                                                                : apiData[collumn.name]
                                                                }
                                                            </td>
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                            }

                        </tbody>
                    </table>
                </div>
                <div className={`flex flex-col w-full gap-2 h-full p-2 sm:hidden items-center justify-center overflow-y-auto`}>
                    {
                        tableData?.length > 0 ?
                            tableData.map((apiData, index) => {
                                return (
                                    <div key={index} className='flex flex-col h-full w-full pb-4'>
                                        <div className='flex flex-col w-full boxShadow mt-2'>
                                            <div className='flex flex-col w-full'>
                                                {
                                                    collumns.map((titleColumn, indexMobile) => {
                                                        return <div key={`${index}-${indexMobile}`} className='flex flex-row w-full'>
                                                            <div className='h-12 flex flex-col w-36 bg-primaryDefaultLight dark:bg-thirdDefaultDark text-bgPrimaryLight font-extrabold justify-center items-start pl-2'>
                                                                <a className='text-sm font-extrabold text-bgPrimaryLight dark:text-titleGrayTextLight'>{titleColumn.description}</a>
                                                            </div>
                                                            <div className='h-12 w-full flex flex-col justify-center items-start'>
                                                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark pl-2'>
                                                                    {
                                                                        titleColumn.type == 'date'
                                                                            ? formattTimeToShow(apiData[titleColumn.name])
                                                                            : titleColumn?.func
                                                                                ? titleColumn?.func(apiData)
                                                                                : apiData[titleColumn.name]?.toString().length > 30
                                                                                    ? apiData[titleColumn.name]?.toString().slice(0, 30).concat('...')
                                                                                    : apiData[titleColumn.name]?.toString()
                                                                    }
                                                                </a>
                                                            </div>

                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className='w-full bg-primaryDefaultLight dark:bg-thirdDefaultDark h-12 flex flex-row items-center justify-start pl-2 gap-10 border-t border-primebg-primaryDefaultLight'>
                                                <div>
                                                    <a className='text-sm text-bgPrimaryLight font-extrabold'>Opções</a>
                                                </div>
                                                {mobileFunctions.map((func, indexFuncIcon) => {
                                                    return <div key={`${index}-${indexFuncIcon}-`} onClick={() => onClick({ data: apiData, func: func })} className='flex flex-col items-center justify-center cursor-pointer h-full w-12' title={func.title}>
                                                        <div className='text-2xl text-bgPrimaryLight'>{func.icon}</div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <h1>Não há lançamentos.</h1>
                    }
                    <div className='p-4 flex'>
                        {
                            [...Array(totalPages)?.keys()].map((thisPage, index) => {

                                const buttonTrue = <a onClick={() => setSelectedPage(thisPage + 1)} className='text-white px-2 bg-primaryDefaultLight hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage + 1}</a>
                                const buttonFalse = <a onClick={() => setSelectedPage(thisPage + 1)} className='px-2 hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage + 1}</a>
                                let existsPoints = false
                                let showPointers = false

                                if (totalPages < 6) {
                                    return selectedPage === thisPage + 1 ? buttonTrue : buttonFalse
                                } else {
                                    existsPoints = true
                                    if (index + 1 === 1 && selectedPage !== thisPage + 1) {
                                        return buttonFalse
                                    } else if (index + 1 == thisPage + 1 && selectedPage === thisPage + 1) {
                                        return buttonTrue
                                    } else if (selectedPage + 3 > index + 1 && selectedPage - 2 < index + 1) {
                                        return buttonFalse
                                    } else if (totalPages === thisPage + 1) {
                                        return buttonFalse
                                    } else {
                                        if (selectedPage + 3 < index + 1 || selectedPage - 2 > index + 1) {
                                            showPointers = true
                                        }
                                        if (existsPoints && showPointers == false) {
                                            return <a>...</a>
                                        }
                                    }
                                }
                            })
                        }
                    </div>
                </div>
                {
                    dynamicloading ? 
                    <div className='flex flex-col justify-center items-center w-full'>
                        <LoadingPulse />
                    </div>
                    : totalPages === selectedPage || totalPages === 0 ? <></>
                    : <button 
                        id="testes" 
                        className='h-2 flex bg-white w-32 m-auto  justify-center items-center text-white rounded-xl'
                    >
                        Ver mais +
                    </button>
                }
            </div >
    )
}