import { FaCloudDownloadAlt, FaCloudUploadAlt, FaEdit, FaLock, FaPlus, FaTrash, FaUnlock } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Body } from "../../../components/container/Body";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { LabelInput } from "../../../components/label/label.input";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { Button } from "../../../components/buttons/button.default";
import { Input } from "../../../components/input/input";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";

//api
import { api } from "../../../services/api/api";
//context
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
//component
import { TableScrollDefault } from "../../../components/table/tableScroll.default ";
import { CashFlowMobileEdit } from "./cash.Flow.Mobile.Edit";
import { Toggle } from '../../../components/toogle/Toogle'
//utils
import { validateFields } from "../../../utils/form.validator";
import { responseError } from "../../../utils/responsesFunctions/error.response";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';

import moment from "moment";
//utils
import FileDropZone from "../../../components/dropZone/dropZone";
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";

export function CashFlow() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const [period, setPeriod] = useState('')
    const { screenX } = useScreenSizeContext()
    const { showUniversalModal, setShowUniversalModal } = useThemeContext()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [loading, setLoading] = useState(true)
    const [cleanModal, setCleanModal] = useState(false)

    //create
    const [sendDate, setSendDate] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [docNumber, setDocNumber] = useState('')
    const [originalValue, setOriginalValue] = useState('')
    const [interestValue, setInterestValue] = useState('')
    const [fineValue, setFineValue] = useState('')
    const [observation, setObservation] = useState('')
    const [accountPlans, setAccountPlans] = useState('')
    const [supplierAndCustomer, setSupplierAndCustomer] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [banksAccounts, setBanksAccounts] = useState('')

    const [getPeriod, setGetPeriod] = useState([])
    const [accountPlansData, setAccountPlansData] = useState([])
    const [supplierAndCustomerData, setSupplierAndCustomerData] = useState([])
    const [banksAccountsData, setBanksAccountsData] = useState([])
    const [toTable, setToTable] = useState(false)
    const [status, setStatus] = useState(false)
    const [filter, setFilter] = useState({})
    const [detectModal, setDetectModal] = useState('')
    const [temporaryData, setTemporaryData] = useState()
    const [button1Pressed, setButton1Pressed] = useState(false)
    const [button2Pressed, setButton2Pressed] = useState(false)
    const [periodIsOpen, setPeriodIsOpen] = useState(false)
    const [editMobile, setEditMobile] = useState(false)
    const [showImports, setShowImports] = useState(false)
    const [idTemp, setIdTemp] = useState()
    const [sendFile, setSendFile] = useState('')
    const [concatSendsLate, setConcatSendsLate] = useState(false)
    const [valueSearch, setValueSearch] = useState('')

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (!showUniversalModal) {
            setTemporaryData('')
            setSendDate('')
            setDueDate('')
            setDocNumber('')
            setOriginalValue('')
            setFineValue('')
            setObservation('')
            setAccountPlans('')
            setSupplierAndCustomer('')
            setBanksAccounts('')
            setInterestValue('')
            setPaymentDate('')
            setAccountPlans('')
            setSupplierAndCustomer('')
            setBanksAccounts('')
            setInterestValue('')
            setPaymentDate('')
        }

    }, [detectModal, showUniversalModal, cleanModal])

    async function getData(){
        setLoading(true)
        try {
            const getPeriodsApi = await api.get(`/api/v1/general/periods`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setGetPeriod(getPeriodsApi?.data?.data?.map(item => {
                return {
                    ...item,
                    valueToShowSelected: (item.month.toString().length == 1 ? '0' + item.month : item.month) + '/' + item.year,
                    value: (item.month.toString().length == 1 ? '0' + item.month : item.month) + '/' + item.year,
                    success: item.isOpen
                }
            }))

            const accountPlansApi = await api.get(`/api/v1/general/accountPlans`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setAccountPlansData(accountPlansApi?.data?.data)

            const supplierAndCustomerApi = await api.get(`/api/v1/general/supplierAndCustomer/`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setSupplierAndCustomerData(supplierAndCustomerApi?.data?.data)   

            const banksAccountsApi = await api.get(`/api/v1/fc/banksAccounts`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setBanksAccountsData(banksAccountsApi?.data?.data.filter(item => item?.active === true))
            setLoading(false)

        } catch (error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    function cashFlowEdit(id) {
        setIdTemp(id?.id)
        setEditMobile(true)
    }

    function cashFlowDelete(item) {
        setTemporaryData(item)
        setDetectModal("3")
        setShowUniversalModal(true)
    }

    async function handleSubmit() {

        const requiredFields = [
            { name: 'dueDate', value: dueDate, required: true, type: 'string' },
            { name: 'docNumber', value: docNumber, required: true, type: 'string' },
            { name: 'originalValue', value: originalValue, required: true, type: 'string' },
            { name: 'interestValue', value: interestValue, required: true, type: 'string' },
            { name: 'fineValue', value: fineValue, required: true, type: 'string' },
            { name: 'accountPlans', value: accountPlans, required: true, type: 'string' },
            { name: 'supplierAndCustomer', value: supplierAndCustomer, required: true, type: 'string' },
        ]

        if (paymentDate) {
            requiredFields.push({ name: 'banksAccounts', value: banksAccounts, required: true, type: 'object' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }
        setLoading(true)
        try {

            await api.post('api/v1/fc/sends', {
                sendDate: moment(period?.value, 'MM/YYYY')?.format('YYYY-MM-01'),
                dueDate: dueDate,
                docNumber: docNumber,
                originalValue: originalValue,
                interestValue: interestValue,
                fineValue: fineValue,
                paymentDate: paymentDate ? paymentDate : undefined,
                observation: observation ? observation : undefined,
                g_accountPlans_code: accountPlans.code,
                g_supplierAndCustomer_id: supplierAndCustomer.id,
                fc_banksAccounts_id: banksAccounts.id,
                periods_id: filter.g_periods_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Lançamento realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

    }

    async function handleDelete(e) {
        setLoading(true)

        try {

            await api.delete(`api/v1/fc/sends/${e.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setShowUniversalModal(false)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleEditCashflow() {
        const requiredFields = [
            { name: 'dueDateEdit', value: temporaryData?.dueDate, required: true, type: 'string' },
            { name: 'docNumberEdit', value: temporaryData?.docNumber, required: true, type: 'string' },
            { name: 'originalValueEdit', value: temporaryData?.originalValue, required: true, type: 'string' },
            { name: 'interestValueEdit', value: temporaryData?.interestValue, required: true, type: 'string' },
            { name: 'fineValueEdit', value: temporaryData?.fineValue, required: true, type: 'string' },
            { name: 'accountPlansEdit', value: temporaryData?.g_accountPlans_code, required: true, type: 'string' },
            { name: 'supplierAndCustomerEdit', value: temporaryData?.g_supplierAndCustomer_id, required: true, type: 'string' },
        ]

        if (temporaryData?.paymentDate) {
            requiredFields.push({ name: 'banksAccountsEdit', value: temporaryData?.fc_banksAccounts_id, required: true, type: 'object' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        setLoading(true)
        try {
            await api.put(`api/v1/fc/sends/${temporaryData.id}`, {
                sendDate: temporaryData?.sendDate,
                dueDate: temporaryData?.dueDate,
                docNumber: temporaryData?.docNumber,
                originalValue: temporaryData?.originalValue,
                interestValue: temporaryData?.interestValue,
                fineValue: temporaryData?.fineValue,
                observation: temporaryData?.observation ? temporaryData?.observation : undefined,
                paymentDate: temporaryData?.paymentDate ? temporaryData?.paymentDate : undefined,
                g_accountPlans_code: Number(temporaryData?.g_accountPlans_code),
                g_supplierAndCustomer_id: Number(temporaryData?.g_supplierAndCustomer_id),
                fc_banksAccounts_id: temporaryData?.fc_banksAccounts_id ? Number(temporaryData?.fc_banksAccounts_id?.id) : undefined,
                periods_id: filter.g_periods_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

    }

    async function downloadCSV() {
        setLoading(true)

        const dataExportsModel = await api.get(`/api/v1/fc/imports/exportModel/true`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: `Bearer ${userData[0].token}`
            }
        })

        let fileObj = URL.createObjectURL(dataExportsModel?.data)
        let myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute("download", `Lancamentos_${Number((new Date))}`)
        myElement.click()

        setLoading(false)
    }

    useEffect(() => {
        if (periodIsOpen) {
            if (button1Pressed && button2Pressed) {
                setShowUniversalModal(true)
                setDetectModal("1")
                // Aqui você pode executar a ação que deseja
            }
        }
    }, [button1Pressed, button2Pressed]);

    async function handleImports() {

        if (!sendFile) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText("Selecione um arquivo para realizar a importação.")
        }
        setLoading(true)
        setShowUniversalModal(false)
        try {

            const formData = new FormData();
            formData.append('files', sendFile);

            await api.post(`/api/v1/fc/imports/importSends`, formData, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
            setSendFile('')
            setShowModificationModal(true)
            setShowNotificationModalText('Importação realizada com sucesso')            
            setShowNotificationModalSuccess(true)
            setShowImports(false)
            getData()
            setLoading(false)

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function exportSends(){
        try {

        const urlContructor = ['']

        Object.keys(filter).map(key => {
            if(![null, undefined, '', NaN].includes(filter[key])){
                urlContructor.push(`${key}=${filter[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/fc/sends/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `Lancamentos_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    async function showSendsLate(){

        const status = !concatSendsLate

        if (status){
            const formatPeriod = moment(period?.value, 'MM/YYYY')
            setFilter({
                dueDate: formatPeriod.add(-1, 'day').format('YYYY-MM-DD'),
                orderByDirection: 'ASC',
                orderBy: 'dueDate',
                g_periods_id: period?.id
            })
        } else {
            setFilter({
                g_periods_id: period?.id
            })
        }
    }

    return (
        <>
            {
                //modal to create cash flow
                showUniversalModal && detectModal === "1" ?
                <ScreenModal width={screenX > 1023 && screenX < 1280 ? 350 : screenX >= 1280 ? 1100 : 350} title={'Lançar fluxo de caixa'}>
                    {
                        <div className="px-10 flex flex-col xl:grid grid-cols-3 justify-center items-center gap-5 p-5 md:h-[full] max-w-[1200px] overflow-y-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400">
                            <LabelInput text={'Vencimento *'}>
                                <Input
                                    id='dueDate'
                                    type={'date'}
                                    value={dueDate ? dueDate : ''}
                                    onChange={(e) => setDueDate(e.target.value)}
                                    width={80}
                                />
                            </LabelInput>
                            <LabelInput text={'Data de pagamento '}>
                                <Input
                                    id='paymentDate'
                                    type={'date'}
                                    value={paymentDate ? paymentDate : ''}
                                    onChange={(e) => {
                                        setPaymentDate(e.target.value)
                                    }}
                                    width={80}
                                />
                            </LabelInput>
                            <LabelInput text={'Número Documento *'}>
                                <Input
                                    id='docNumber'
                                    type={'text'}
                                    value={docNumber ? docNumber : ''}
                                    onChange={(e) => setDocNumber((e.target.value))}
                                    charLimit={200}
                                    width={80}
                                />
                            </LabelInput>
                            <LabelInput text={'Banco '} required={paymentDate || false}>
                                <InputAutoComplete
                                    id='banksAccounts'
                                    data={banksAccountsData}
                                    selectedLabel={['description']}
                                    optionList={['id', 'description']}
                                    type={'text'}
                                    height={160}
                                    value={banksAccounts ? banksAccounts.description : ''}
                                    onChange={(e) => setBanksAccounts(e)}
                                    width={80}
                                />
                            </LabelInput>
                            <LabelInput text={'Fornecedor/Cliente *'}>
                                <InputAutoComplete
                                    id='supplierAndCustomer'
                                    type={'text'}
                                    selectedLabel={['businessName']}
                                    optionList={['id', 'businessName']}
                                    width={80}
                                    height={160}
                                    data={supplierAndCustomerData}
                                    value={supplierAndCustomer ? supplierAndCustomer.businessName : ''}
                                    onChange={(e) => setSupplierAndCustomer(e)} />
                            </LabelInput>
                            <LabelInput text={'Plano de contas *'}>
                                <InputAutoComplete
                                    id='accountPlans'
                                    data={accountPlansData}
                                    selectedLabel={'description'}
                                    optionList={['code', 'description']}
                                    height={160}
                                    value={accountPlans ? accountPlans?.description : ''}
                                    width={80}
                                    onChange={(e) => setAccountPlans(e)} />
                            </LabelInput>
                            <LabelInput text={'Valor Principal *'}>
                                <CurrencyInput id='originalValue' placeHolder='R$ 0,00'
                                    onChange={(e) => {
                                        setOriginalValue(e.target.value ? parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')) : '')
                                    }}></CurrencyInput>
                            </LabelInput >
                            <LabelInput text={'Juros *'}>

                                <CurrencyInput id='interestValue' placeHolder='R$ 0,00' onChange={(e) => {
                                    setInterestValue(e.target.value ? parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')) : '')
                                }}></CurrencyInput>
                            </LabelInput>
                            <LabelInput text={'Multa *'}>
                                <CurrencyInput id='fineValue' placeHolder='R$ 0,00' onChange={(e) => {
                                    setFineValue(e.target.value ? parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')) : '')
                                }}></CurrencyInput>
                            </LabelInput>
                            <LabelInput text={'Observação'}>
                                <Input type={'text'}
                                    id='observation'
                                    value={observation ? (observation) : ''}
                                    onChange={(e) => setObservation(e.target.value)}
                                    charLimit={200}
                                    width={80}
                                />
                            </LabelInput>
                        </div>
                    }
                    <div className='m-3 pb-2'>
                        <Button onClick={() => handleSubmit()}>+ Lançar</Button>
                    </div>
                </ScreenModal>
                : showUniversalModal && detectModal === "2" ?
                <ScreenModal width={screenX > 1023 && screenX < 1280 ? 350 : screenX >= 1280 ? 1150 : screenX > 700 && screenX < 1023 ? 650 : 350} title={'Editar Lançamento '}>
                    <div className="px-10 flex flex-col xl:grid grid-cols-3 justify-center items-center gap-5 p-5 md:h-[full] max-w-[1200px] overflow-y-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400">
                        <LabelInput text={'Vencimento *'}>
                            <Input
                                id='dueDateEdit'
                                type={'date'}
                                width={80}
                                value={temporaryData?.dueDate ? temporaryData?.dueDate : ''}
                                onChange={(e) => setTemporaryData(prev => ({ ...prev, dueDate: e.target.value }))}
                            />
                        </LabelInput>
                        <LabelInput text={'Data de pagamento '}>
                            <Input
                                id='paymentDateEdit'
                                type={'date'}
                                width={80}
                                value={temporaryData?.paymentDate ? temporaryData?.paymentDate : ''}
                                onChange={(e) => setTemporaryData(prev => ({ ...prev, paymentDate: e.target.value }))}
                            />
                        </LabelInput>
                        <LabelInput text={'Número Documento *'}>
                            <Input
                                id='docNumberEdit'
                                type={'text'}
                                width={80}
                                value={temporaryData?.docNumber ? temporaryData?.docNumber : ''}
                                onChange={(e) => setTemporaryData(prev => ({ ...prev, docNumber: e.target.value }))}
                                charLimit={200}
                            />

                        </LabelInput>
                        <LabelInput text={'Banco'} required={temporaryData?.paymentDate || false}>
                            <InputAutoComplete
                                id='banksAccountsEdit'
                                data={banksAccountsData}
                                selectedLabel={['description']}
                                optionList={['id', 'description']}
                                type={'text'}
                                width={80}
                                preSelectedValue={temporaryData?.fc_banksAccounts_id ? banksAccountsData.filter(item => item.id === temporaryData?.fc_banksAccounts_id)[0]?.description : ''}
                                value={temporaryData?.fc_banksAccounts_id ? banksAccountsData.filter(item => item.id === temporaryData?.fc_banksAccounts_id?.id)[0]?.description : ''}
                                onChange={(e) => setTemporaryData(prev => ({ ...prev, fc_banksAccounts_id: e }))}
                            />
                        </LabelInput>
                        <LabelInput text={'Fornecedor/Cliente *'}>
                            <InputAutoComplete
                                id='supplierAndCustomerEdit'
                                type={'text'}
                                selectedLabel={['businessName']}
                                optionList={['id', 'businessName']}
                                width={80}
                                data={supplierAndCustomerData}
                                preSelectedValue={temporaryData?.g_supplierAndCustomer_id ? supplierAndCustomerData.filter(item => item.id === temporaryData?.g_supplierAndCustomer_id)[0]?.businessName : ''}
                                value={temporaryData?.g_supplierAndCustomer_id ? supplierAndCustomerData.filter(item => item.id === temporaryData?.g_supplierAndCustomer_id)[0]?.businessName : ''}
                                onChange={(e) => setTemporaryData(prev => ({ ...prev, g_supplierAndCustomer_id: e?.id }))} />
                        </LabelInput>
                        <LabelInput text={'Plano de contas *'}>
                            <InputAutoComplete
                                id='accountPlansEdit'
                                data={accountPlansData}
                                selectedLabel={'description'}
                                width={80}
                                optionList={['code', 'description']}
                                preSelectedValue={temporaryData?.g_accountPlans_code ? accountPlansData.filter(item => item.code === temporaryData?.g_accountPlans_code)[0]?.description : ''}
                                value={temporaryData?.g_accountPlans_code ? accountPlansData.filter(item => item.code === temporaryData?.g_accountPlans_code)[0]?.description : ''}
                                onChange={(e) => setTemporaryData(prev => ({ ...prev, g_accountPlans_code: e?.code }))} />

                        </LabelInput>
                        <LabelInput text={'Valor Principal *'}>
                            <CurrencyInput id='originalValueEdit'
                                placeHolder={temporaryData?.originalValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(temporaryData?.originalValue) : 'R$ -'} onChange={(e) => {
                                    setTemporaryData(prev => ({ ...prev, originalValue: e.target.value ? parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')) : '' }))
                                }}></CurrencyInput>
                        </LabelInput >
                        <LabelInput text={'Juros *'}>
                            <CurrencyInput id='interestValueEdit'
                                placeHolder={temporaryData?.interestValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(temporaryData?.interestValue) : 'R$ -'} onChange={(e) => {
                                    setTemporaryData(prev => ({ ...prev, interestValue: e.target.value ? parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')) : '' }))
                                }}></CurrencyInput>
                        </LabelInput>
                        <LabelInput text={'Multa *'}>
                            <CurrencyInput id='fineValueEdit'
                                placeHolder={temporaryData?.fineValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(temporaryData?.fineValue) : 'R$ -'} onChange={(e) => {
                                    setTemporaryData(prev => ({ ...prev, fineValue: e.target.value ? parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')) : '' }))
                                }}></CurrencyInput>
                        </LabelInput>
                        <LabelInput text={'Anotações'}>
                            <Input type={'text'}
                                id='observationEdit'
                                value={temporaryData?.observation ? temporaryData?.observation : ''}
                                onChange={(e) => setTemporaryData(prev => ({ ...prev, observation: e.target.value }))}
                                charLimit={200}
                                width={80}
                            />
                        </LabelInput>
                    </div>
                    <div className='m-3 flex justify-between w-full px-20 pb-3'>
                        <Button approval={false} onClick={() => setShowUniversalModal(false)}>Cancelar </Button>

                        <div className='flex items-center gap-3 '>
                            {
                                (userData[0]?.permissions?.indexOf('fc_sends:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                <Button approval={false} onClick={() => { setShowUniversalModal(true); setDetectModal("3"); setCleanModal(true) }}>Excluir </Button>
                            }
                            {
                                (userData[0]?.permissions?.indexOf('fc_sends:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                <Button onClick={() => handleEditCashflow()}>Editar </Button>
                            }
                        </div>
                    </div>
                </ScreenModal>
                : showUniversalModal && detectModal === "4" ?
                <ScreenModal title={'Importar Lançamentos'} width={screenX > 400 ? '400px' : '150px'} height={200} >
                    <div className='w-full h-full flex flex-col items-center justify-center mt-5'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente importar os lançamentos?</p>
                        <div className='flex flex-row gap-1 mt-5'>
                            <Button onClick={() => handleImports()}>Sim </Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
                :
                showUniversalModal && detectModal === "3" &&
                <ScreenModal title={'Exclusão de lançamento'} width={400} >
                    <div className='w-full h-full flex flex-col items-center justify-center mt-5'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o lançamento ?</p>
                        <div className='flex flex-row gap-1 mt-5 pb-4'>
                            <Button onClick={() => handleDelete(temporaryData)}>Sim </Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            <Container>
                {
                    editMobile ?
                    <CashFlowMobileEdit id={idTemp} setEditMobile={setEditMobile} />
                    :
                    <>
                        <TitlePage>
                            <div className='flex justify-between w-full'>
                                <Title text={'Lançamentos'} />
                                <Button onClick={() => {exportSends()}} height={7}>
                                    <FaCloudDownloadAlt className='text-white' /> <p className='text-white pl-2'> Exportar </p>
                                </Button>
                            </div>
                        </TitlePage>
                        <Body>
                            {
                                loading ?
                                <DefaultLoader />
                                : showImports ?
                                <>
                                    <div className="flex flex-col justify-start items-start gap-1 w-full">
                                        <h1 className="text-base text-red-500">Atenção às regras de importação.</h1>
                                        <p className="text-sm">
                                            <span className="font-semibold">Regras de importação:</span> Utilizar o arquivo base para importação, o arquivo poderá ser baixado no botão abaixo ou clicando <button className="underline" onClick={() => downloadCSV()}>aqui</button>
                                        </p>
                                        <p className="text-sm">
                                            <span className="font-semibold">Campos obrigatórios:</span> "Dia da Competência", "Vencimento", "Número do documento", "Fornecedor / Cliente", "Plano de contas", "Valor principal".
                                        </p>
                                        <p className='text-sm'>
                                            <i>Obs: Valores monetários informar com vírgula, exemplo: 1000,90</i>
                                        </p>
                                        <Button onClick={() => downloadCSV()}>Baixar</Button>
                                    </div>
                                    <div className="w-full">
                                        <FileDropZone setSendFile={setSendFile} />
                                    </div>
                                    <div className="flex gap-5">
                                        <Button onClick={() => setShowImports(false)} approval={false} width={20}>Voltar</Button>
                                        <Button onClick={() => { setDetectModal("4"); setShowUniversalModal(true) }}  >Importar</Button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={`button--button monthSelected--monthSelected flex flex-row items-end ${toTable ? 'justify-between' : 'justify-center'} w-full`}>
                                        <div className="flex flex-row gap-5 items-end">
                                            <LabelInput text={'Selecione a competência:'}>
                                                <InputAutoComplete
                                                    width={60}
                                                    data={getPeriod}
                                                    icon={{
                                                        icon: <FaUnlock />,
                                                        compareIcon: <FaLock />
                                                    }}
                                                    colorIcon={'dark:text-green-500 text-green-800'}
                                                    colorCompareIcon={'dark:text-green-500 text-red-800'}
                                                    value={period ? period?.valueToShowSelected : ''}
                                                    preSelectedValue={period ? period?.valueToShowSelected : ''}
                                                    id='period'
                                                    selectedLabel={['valueToShowSelected']}
                                                    optionList={['value']}
                                                    onChange={(e) => {
                                                        if (typeof (e) == "object") {
                                                            setConcatSendsLate(false)
                                                            setFilter({ g_periods_id: e?.id })
                                                            setPeriod(e)
                                                            setPeriodIsOpen(e?.isOpen)
                                                            setToTable(true)
                                                            setStatus(true)
                                                        }
                                                    }}
                                                />
                                                {
                                                    getPeriod?.length == 0 ?
                                                    <div className="flex justify-center items-center">
                                                        <a 
                                                            onClick={() => navigate('/determinationPeriod')} 
                                                            className="underline text-primaryDefaultLight text-md cursor-pointer hover:brightness-75 text-center mt-5"
                                                        >
                                                            Cadastrar período
                                                        </a> 
                                                    </div>
                                                    : <></>
                                                }
                                            </LabelInput>
                                            {
                                                period ?
                                                <>
                                                    <form onSubmit={(e) => {e.preventDefault(); setFilter({...filter, text: valueSearch})}}>
                                                        <LabelInput text={'Pesquisar'}>
                                                            <Input
                                                                id={'filterText'}
                                                                value={valueSearch ? valueSearch : ''}
                                                                onChange={(e) => setValueSearch(e?.target.value)}
                                                            />
                                                        </LabelInput>
                                                    </form>
                                                    <Tippy
                                                        content={'Mostrar em atraso'}
                                                        arrow={true}
                                                        animation='perspective'
                                                        placement='top'
                                                        delay={80}
                                                    >
                                                        <div>
                                                            <Toggle
                                                                status={concatSendsLate}
                                                                onClick={(e) => {setConcatSendsLate(!concatSendsLate); showSendsLate()}}
                                                                greenAndRed={true}
                                                            />
                                                        </div>
                                                    </Tippy>
                                                </>
                                                : <></>
                                            }                                            
                                        </div>
                                        {
                                            toTable && periodIsOpen ? (
                                                <div>
                                                    <div className="hidden sm:flex items-center gap-5">
                                                        {
                                                            (userData[0]?.permissions?.indexOf('fc_sends:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                            <>
                                                                <div>
                                                                    <Tippy
                                                                        content={<span>Importar lançamento</span>}
                                                                        arrow={true}
                                                                        animation='perspective'
                                                                        placement='top'
                                                                        delay={80}
                                                                    >
                                                                        <div onClick={() => setShowImports(true)} className="text-xl text-blue-600 cursor-pointer">
                                                                            <FaCloudUploadAlt />
                                                                        </div>
                                                                    </Tippy>
                                                                </div>
                                                                <div>
                                                                    <Button onClick={() => { setShowUniversalModal(true); setDetectModal('1') }}>+ Lançamentos</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    {
                                                        (userData[0]?.permissions?.indexOf('fc_sends:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                        <div className="mt-[25px] md:mt-0 px-1 flex flex-col items-center gap-3 sm:hidden">
                                                            <Button onClick={() => { setShowUniversalModal(true); setDetectModal('1') }}><FaPlus /></Button>
                                                        </div>
                                                    }
                                                </div>
                                            ) : period ? (
                                                <div className="flex items-center text-sm text-red-800 gap-2 pr-5">
                                                    <p>Período fechado</p>
                                                    <FaLock />
                                                </div>
                                            ) : <></>
                                        }
                                    </div>
                                    <>
                                        {
                                            toTable &&
                                            <>
                                                <div className='flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                                    <TableScrollDefault
                                                        onClick={(e) => {
                                                            if (periodIsOpen) {
                                                                if (e.func) {
                                                                    e.func.execute(e.data)
                                                                } else {
                                                                    setTemporaryData(e)
                                                                    setDetectModal('2')
                                                                    setShowUniversalModal(true)
                                                                }
                                                            }
                                                        }}
                                                        apiUrl={`/api/v1/fc/sends`}
                                                        toTable={toTable}
                                                        filters={filter}
                                                        status={status}
                                                        mobileFunctions={[
                                                            { "title": "Editar", "icon": <FaEdit />, "execute": cashFlowEdit },
                                                            { "title": "Excluir", "icon": <FaTrash />, "execute": cashFlowDelete }
                                                        ]}
                                                        collumns={[
                                                            {
                                                                name: 'sendDate',
                                                                type: 'string',
                                                                description: 'Competência',
                                                                func: (item) => moment(item?.sendDate).format('MM/YYYY')

                                                            },
                                                            {
                                                                name: 'dueDate',
                                                                type: 'string',
                                                                description: 'Venci.',
                                                                func: (item) => moment(item?.dueDate).format('DD/MM/YYYY')

                                                            },
                                                            {
                                                                name: 'paymentDate',
                                                                type: 'string',
                                                                description: 'Pagam.',
                                                                func: (item) => item.paymentDate !== null ? moment(item.paymentDate).format('DD/MM/YYYY') : "-"
                                                            },
                                                            {
                                                                type: 'string',
                                                                description: 'Status',
                                                                func: (item) => 
                                                                Number(new Date(moment().format('YYYY-MM-DD'))) > Number(new Date(item?.dueDate)) && !item.paymentDate ?
                                                                <div className="flex justify-center items-center">
                                                                    <h1 className="bg-red-500 w-20  rounded-lg text-white text-center">Em atraso</h1>
                                                                </div> 
                                                                : item.paymentDate ? 
                                                                <div className="flex justify-center items-center">
                                                                    <h1 className="bg-green-500 w-20 text-center rounded-lg text-white">Pago</h1>
                                                                </div> 
                                                                : !item.paymentDate && Number(new Date(moment().format('YYYY-MM-DD'))) <= Number(new Date(item?.dueDate)) &&
                                                                <div className="flex justify-center items-center">
                                                                    <h1 className="bg-yellow-500 w-20 text-center rounded-lg text-white">Pendente</h1>
                                                                </div>
                                                            },
                                                            {
                                                                name: 'account_type',
                                                                type: 'string',
                                                                description: 'Tipo',
                                                                func: (item) => item.account_type === "C" ? "Credor" : "Devedor"

                                                            },
                                                            {
                                                                name: 'accountBank_description',
                                                                type: 'string',
                                                                description: 'Banco',

                                                            },
                                                            {
                                                                name: 'supplier_businessName',
                                                                type: 'string',
                                                                description: 'Forn/Cliente',

                                                            },
                                                            {
                                                                name: 'account_description',
                                                                type: 'string',
                                                                description: 'Conta',
                                                                func: (item) => item.g_accountPlans_code + " - " + item.account_description

                                                            },
                                                            {
                                                                name: 'originalValue',
                                                                type: '',
                                                                description: 'Val.Total',
                                                                func: (item) => Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' }).format(item.fineValue + item.originalValue + item.interestValue)

                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </>
                                </>
                            }
                        </Body>
                    </>
                }
            </Container>
        </>
    )
}